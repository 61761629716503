<template>
    <page-layout-nolo>
        <h6>Session</h6>
        <tree-view :data="session" :options="{maxDepth: 1}" ></tree-view>
        <h6>App</h6>
        <tree-view :data="app" :options="{maxDepth: 1}" ></tree-view>
    </page-layout-nolo>
</template>

<script>
    import Vue from 'vue';
    import TreeView from "vue-json-tree-view";
    Vue.use(TreeView);

    export default {
        name: "Session",
        computed: {
            session() {
                return JSON.parse(JSON.stringify(this.$store.getters['session/all']));
            },
            app() {
                return this.$store.getters['app/all'];
            }
        }
    }
</script>

<style scoped>

</style>
